import { FC, useCallback, useMemo, useState } from "react";
import { Box, TextField, Button, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import { Account } from "../hooks/useWalletList";
import { PublicClient } from "viem";
import { adaptViemWallet, createClient, reservoirChains } from "@reservoir0x/reservoir-sdk";
import bn from "bignumber.js";
import { ERC721Abi } from "../abis";
import { useAddressTokens } from "../hooks";
import { getNft, listEventsByNft, OrderEventModel } from "../api/gen/opensea";
import { OPENSEA_API_KEY, OPENSEA_OPERATORS, RESERVOIR_API_KEY } from "../settings";
import { Chain, OpenSeaSDK } from "opensea-js";
import moment from "moment";
import { clientToSigner } from "../helpers";
import { Wallet } from "ethers";
import { formatAddress } from "../helpers/address";
import { Log } from "../hooks/useLogs";
import ContentLoader from "react-content-loader";
import { Marketplace } from "../models/Marketplace";
import { toast } from "react-toastify";

interface AddressDataTableProps {
    activeChainId: number;
    accounts: Account[];
    pubClientsMap: Map<number, PublicClient>;
    tokenAddress: string | undefined;
    activeSet: Set<string>;
    toggleActive: (account: string) => void;
    toggleAllActive: () => void;
    addLog: (message: string, type?: Log["type"]) => void;
    balances: number[];
    tokenBalances: number[];
    approvalsOs: boolean[];
    approvalsBlur: boolean[];
    updateAccountsMeta: () => Promise<void>;
    isLoadingBalances: boolean;
}

const AddressDataTable: FC<AddressDataTableProps> = ({ activeChainId, accounts, pubClientsMap, tokenAddress, activeSet, toggleActive, toggleAllActive, addLog, tokenBalances, balances, updateAccountsMeta, approvalsOs, isLoadingBalances }) => {

    const toggleSet = (address: string) => {
        toggleActive(address.toLowerCase());
    }

    const scanBaseUrl = useMemo(() => {
        if (activeChainId === 1) {
            return "https://etherscan.io";
        }
        if (activeChainId === 8453) {
            return "https://basescan.org";
        }
        if (activeChainId === 33139) {
            return "https://apescan.io";
        }
    }, [activeChainId]);

    const onInitApprove = async () => {
        const pubClient = pubClientsMap.get(activeChainId);

        addLog("Started approvals");

        if (!pubClient) {
            return;
        }

        for (const value of activeSet.values()) {
            const account = accounts.find(account => account.address.toLowerCase() === value);

            if (account) {
                const client = account.walletClients.get(activeChainId);

                if (!client) {
                    console.error("No wallet client found for account:", account.address, " and chain id: ", activeChainId);

                    return;
                }

                // @ts-ignore
                const isApprovedAlready = await pubClient.readContract({
                    account: client.account,
                    address: tokenAddress as `0x${string}`,
                    abi: ERC721Abi,
                    functionName: "isApprovedForAll",
                    args: [OPENSEA_OPERATORS[activeChainId]],
                })
                    .then((res: any) => res as boolean)
                    .catch((err: Error) => {
                        addLog(`Failed to check approval for ${account.address}. Error: ${err.message}`, "error");
                        return false;
                    });

                if (isApprovedAlready) {
                    continue;
                }

                // @ts-ignore
                const { request } = await pubClient.simulateContract({
                    account: client.account,
                    chain: client.chain,
                    address: tokenAddress as `0x${string}`,
                    abi: ERC721Abi,
                    functionName: "setApprovalForAll",
                    args: [OPENSEA_OPERATORS[activeChainId], true],
                });

                // @ts-ignore
                const tx = await client.writeContract(request)

                // @ts-ignore
                await pubClient.waitForTransactionReceipt({
                    hash: tx,
                }).then((res: any) => {
                    const decimals = new bn(10).pow(18);
                    const gasFormatted = new bn(res.gasUsed.toString()).div(decimals).toNumber().toFixed(6)

                    addLog(`Approved opensea for ${account.address}. Gas used: ${gasFormatted} ETH`, "success");
                    updateAccountsMeta();
                }).catch((err: any) => {
                    addLog(`Failed to approve opensea for ${account.address}. Error: ${err.message}`, "error");
                    toast.error(`Failed to approve opensea for ${account.address}. Error: ${err.message}`);
                })
            }
        }
    }

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Balance ETH</TableCell>
                        <TableCell>Balance NFT</TableCell>
                        <TableCell>Approved</TableCell>
                        <TableCell>
                            <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={activeSet.size === accounts.length} onClick={toggleAllActive} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        accounts.map((account, accountIndex) => (
                            <TableRow key={account.address}>
                                <TableCell>{accountIndex + 1}</TableCell>
                                <TableCell>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`${scanBaseUrl}/address/${account.address}`}
                                    >
                                        {account.address}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {
                                        isLoadingBalances ? (
                                            <ContentLoader viewBox="0 0 50 16">
                                                <rect x="0" y="0" rx="5" ry="5" width="50" height="16" />
                                            </ContentLoader>
                                        ) : (
                                            balances[accountIndex] ?? 0
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        isLoadingBalances ? (
                                            <ContentLoader viewBox="0 0 50 16">
                                                <rect x="0" y="0" rx="5" ry="5" width="50" height="16" />
                                            </ContentLoader>
                                        ) : (
                                            tokenBalances[accountIndex] ?? 0
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={approvalsOs[accountIndex] ?? false} />
                                </TableCell>
                                <TableCell>
                                    <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={activeSet.has(account.address.toLowerCase())} onChange={() => toggleSet(account.address)} />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Button onClick={onInitApprove} disabled={activeSet.size === 0} variant="contained">Approve for listing</Button>
            </Box>
        </Box>
    )
}

export type AdvancedTokenInfo = {
    id: string;
    address: string;
    tokenId: number;
    rarity: string | number;
    name: string;
    imgUrl: string;
    price: string;
    expiresIn: string;
};

interface ListingViewProps {
    activeChainId: number;
    accounts: Account[];
    pubClientsMap: Map<number, PublicClient>;
    addLog: (message: string, type?: Log["type"]) => void;
    tokenAddress: string | undefined;
    setTokenAddress: (val: string | undefined) => void;
    tokenTempAddress: string | undefined;
    setTokenTempAddress: (val: string | undefined) => void;
    balances: number[];
    tokenBalances: number[];
    tokensInfo: AdvancedTokenInfo[];
    setTokensInfo: (tokensInfo: AdvancedTokenInfo[]) => void;
    approvalsOs: boolean[];
    approvalsBlur: boolean[];
    updateAccountsMeta: () => Promise<void>;
    selectedAccountsSet: Set<string>;
    setSelectedAccountsSet: (val: Set<string>) => void;
    selectedTokenInfosSet: Set<string>;
    setSelectedTokenInfosSet: (val: Set<string>) => void;
    allPricesValue: string | undefined;
    setAllPricesValue: (val: string | undefined) => void;
    tokenPriceInputs: Record<string, string>;
    setTokenPriceInputs: (val: Record<string, string>) => void;
    infoDurations: Record<string, number>;
    setInfoDurations: (val: Record<string, number>) => void;
    isLoadingBalances: boolean;
    tokenName: string | undefined;
    setTokenName: (val: string) => void;
    marketplace: Marketplace | undefined;
}

type TimeDuration = [number, "day" | "month" | "hour"];

const Durations = [
    {
        value: [1, "day"] as TimeDuration,
        label: "1 day",
    },
    {
        value: [7, "day"] as TimeDuration,
        label: "7 days",
    },
    {
        value: [1, "month"] as TimeDuration,
        label: "1 month",
    },
];

export const ListingView: FC<ListingViewProps> = ({
    activeChainId,
    accounts,
    pubClientsMap,
    addLog,
    tokenAddress,
    setTokenAddress,
    tokenTempAddress,
    setTokenTempAddress,
    balances,
    tokenBalances,
    tokensInfo,
    setTokensInfo,
    approvalsBlur,
    approvalsOs,
    updateAccountsMeta,
    selectedAccountsSet,
    setSelectedAccountsSet,
    selectedTokenInfosSet,
    setSelectedTokenInfosSet,
    allPricesValue,
    setAllPricesValue,
    tokenPriceInputs,
    setTokenPriceInputs,
    infoDurations,
    setInfoDurations,
    isLoadingBalances,
    tokenName,
    setTokenName,
    marketplace
}) => {
    const getAddressTokens = useAddressTokens(activeChainId);
    const [isLoadingTokensMeta, setIsLoadingTokensMeta] = useState<boolean>(false);

    const selectAllDurations = useCallback((durationIndex: number) => {
        const durations: Record<string, number> = { ...infoDurations };

        for (const tokenInfo of tokensInfo) {
            durations[tokenInfo.id] = durationIndex;
        }

        setInfoDurations(durations);
    }, [infoDurations, setInfoDurations, tokensInfo]);

    const toggleAllAccounts = useCallback(() => {
        let result = new Set<string>();

        if (selectedAccountsSet.size !== accounts.length) {
            result = new Set<string>(accounts.map(account => account.address.toLowerCase()));
        }

        setSelectedAccountsSet(result);
    }, [accounts, selectedAccountsSet.size, setSelectedAccountsSet]);

    const toggleAccount = useCallback((account: string) => {
        const result = new Set<string>(selectedAccountsSet);

        if (result.has(account)) {
            result.delete(account);
        } else {
            result.add(account);
        }

        setSelectedAccountsSet(result);
    }, [selectedAccountsSet, setSelectedAccountsSet]);

    const setTokenPriceInput = useCallback((info: AdvancedTokenInfo, val: string) => {
        const result = { ...tokenPriceInputs };

        result[info.id] = val;

        setTokenPriceInputs(result);
    }, [setTokenPriceInputs, tokenPriceInputs]);

    const setAllTokenInputs = useCallback((val: string) => {
        const newPrices = tokensInfo.reduce((acc, info) => {
            acc[info.id] = val;

            return acc;
        }, {} as Record<string, string>);

        setTokenPriceInputs(newPrices);
    }, [setTokenPriceInputs, tokensInfo]);

    const setInfoDuration = useCallback((info: AdvancedTokenInfo, val: number) => {
        const result = { ...infoDurations };

        result[info.id] = val;

        setInfoDurations(result);
    }, [infoDurations, setInfoDurations]);

    const toggleAllSelectedTokenInfo = () => {
        const selected = new Set<string>(tokensInfo.map((info) => info.id));

        if (selectedTokenInfosSet.size === tokensInfo.length) {
            selected.clear();
        }

        setSelectedTokenInfosSet(selected);
    }

    const toggleSelectedTokenInfo = (info: AdvancedTokenInfo) => {
        const selected = new Set<string>(selectedTokenInfosSet);

        const key = info.id;

        if (selected.has(key)) {
            selected.delete(key);
        } else {
            selected.add(key);
        }

        setSelectedTokenInfosSet(selected);
    }

    const onApplyAddress = () => {
        if (tokenAddress?.toLowerCase() !== tokenTempAddress?.toLowerCase()) {
            setTokenAddress(tokenTempAddress);
        }

        updateAccountsMeta();
    }

    const updateTokenInfos = useCallback(async () => {
        if (tokenAddress) {
            if (tokenAddress.match(/0x[a-fA-F0-9]{40}$/)) {
                const client = pubClientsMap.get(activeChainId);

                if (!client) {
                    addLog(`Failed to update token name, no client exists for: ${activeChainId}`, "error");
                    return;
                }

                // @ts-ignore
                client.readContract({
                    address: tokenAddress as `0x${string}`,
                    abi: ERC721Abi,
                    functionName: "name",
                })
                    .then((res: any) => setTokenName(res as string))
                    .catch((err: Error) => {
                        console.error(err);
                        addLog(`Failed to update token name ${err.message}`);
                    })
            } else {
                setTokenName("Wrong contract");
            }
        }

        const client = pubClientsMap.get(activeChainId);
        setIsLoadingTokensMeta(true);

        if (!client) {
            setIsLoadingTokensMeta(false);
            return;
        }

        if (!tokenAddress) {
            setIsLoadingTokensMeta(false);
            return;
        }

        let result = [];

        for (const account of accounts) {
            if (!selectedAccountsSet.has(account.address.toLowerCase())) {
                continue;
            }

            const tokens = await getAddressTokens(tokenAddress, account.address);

            for (const token of tokens) {
                let chainName = "ethereum" as "ethereum" | "base";

                if (activeChainId === 8453) {
                    chainName = "base";
                }

                if (activeChainId !== 33139) {
                    const meta = await getNft(chainName, tokenAddress, token.identifier, {
                        headers: {
                            "x-api-key": OPENSEA_API_KEY,
                        }
                    }).then((res) => res.data);

                    token.rank = meta?.nft.rarity?.rank;
                }

                let events: any[] = [];

                if (activeChainId !== 33139) {
                    events = await listEventsByNft(chainName, tokenAddress, token.identifier).then((res) => res.data.asset_events);
                }

                const lastSale = events.find((e) => e.event_type === "order" && e.order_type === "listing") as OrderEventModel | undefined;
                let expiresIn = token.expiresAt ? moment(token.expiresAt ?? 0).format("YYYY-MM-DD HH:mm:ss") : "-";

                if (lastSale) {
                    const decimals = new bn(10).pow(18);
                    token.price = new bn(lastSale.payment?.quantity).div(decimals).toNumber();

                    expiresIn = moment((lastSale.expiration_date ?? 0) * 1000).format("YYYY-MM-DD HH:mm:ss");
                }

                const tokenInfo: AdvancedTokenInfo = {
                    id: `${account.address}/${token.identifier}`,
                    tokenId: Number(token.identifier),
                    rarity: token.rank ?? "-",
                    address: account.address,
                    name: token.name,
                    imgUrl: token.image_url ?? "",
                    price: token.price?.toString() ?? "-",
                    expiresIn
                }

                result.push(tokenInfo);
            }
        }

        setIsLoadingTokensMeta(false);
        setTokensInfo(result);
    }, [accounts, activeChainId, addLog, getAddressTokens, pubClientsMap, selectedAccountsSet, setTokenName, setTokensInfo, tokenAddress]);

    const listTokens = async () => {
        const pubClient = pubClientsMap.get(activeChainId);
        addLog(`Listing started`);

        if (!tokenAddress) {
            return;
        }

        if (!pubClient) {
            return;
        }

        for (let infoIndex = 0; infoIndex < tokensInfo.length; infoIndex++) {
            const info = tokensInfo[infoIndex];
            const account = accounts.find((account) => account.address.toLowerCase() === info.address.toLowerCase());

            if (!account) {
                console.error("No account found for address" + info.address.toLowerCase());
                addLog(`No account found for address ${info.address}`, "error");
                toast.error(`No account found for address ${info.address}`);

                continue;
            }

            const walClient = account.walletClients.get(activeChainId);

            if (!walClient) {
                console.error("No wallet client found for account" + info.address);
                addLog(`No wallet client found for account ${info.address}`, "error");
                toast.error(`No wallet client found for account ${info.address}`);

                continue;
            }

            if (selectedTokenInfosSet.has(info.id)) {
                const priceNum = tokenPriceInputs[info.id] ? Number(tokenPriceInputs[info.id]) : -1;
                const durationIndex = infoDurations[info.id] ?? 2;

                if (isNaN(priceNum) || priceNum < 0 || durationIndex === null || durationIndex < 0) {
                    console.log("Price num:", priceNum);
                    console.log("Durations:", infoDurations);
                    console.log("Duration index:", durationIndex);
                    console.error("Not all fields supplied");
                    addLog(`Not all fields supplied for account ${info.address} listing`, "error");
                    toast.error(`Not all fields supplied for account ${info.address} listing`);
                    return;
                }

                const duration = Durations[durationIndex];

                if (!duration) {
                    console.error("No duration found for index:", durationIndex);
                    addLog(`Duration incorrect for account ${info.address} listing`, "error");
                    toast.error(`Duration incorrect for account ${info.address} listing`);

                    continue;
                }

                const expiresInDate = Math.floor(moment().add(duration.value[0], duration.value[1]).toDate().getTime() / 1000);

                // @ts-ignore
                const signer = clientToSigner(walClient);

                if (marketplace === Marketplace.Opensea) {
                    const wallet = new Wallet(account.pk, signer.provider);
                    const openseaSdk = new OpenSeaSDK(wallet, {
                        chain: Chain.Mainnet,
                        apiKey: OPENSEA_API_KEY,
                    });

                    await openseaSdk.createListing({
                        asset: {
                            tokenId: info.tokenId.toString(),
                            tokenAddress,
                        },
                        accountAddress: account.address,
                        startAmount: priceNum,
                        expirationTime: expiresInDate,
                    }).then((res: any) => {
                        addLog(`Opensea: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`, "success");
                        toast.success(`Opensea: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`);
                        console.log("My order:", res);
                    }).catch((err: any) => {
                        console.error("Failed to create listing", err);
                        addLog(`Opensea: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`, "error");
                        toast.error(`Opensea: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`);
                    });
                }

                if (marketplace === Marketplace.Magiceden) {
                    const reservoirSdk = createClient({
                        chains: [{
                            ...reservoirChains.apechain,
                            active: true,
                        }],
                        apiKey: RESERVOIR_API_KEY,
                        source: "opensea.io",
                    });

                    const decimals = new bn(10).pow(18);
                    const price = new bn(priceNum).times(decimals).toFixed();

                    const resWallet = adaptViemWallet(walClient);

                    // @ts-ignore
                    await reservoirSdk.actions.listToken({
                        wallet: resWallet,
                        listings: [
                            {
                                orderKind: "seaport-v1.5",
                                orderbook: "reservoir",
                                token: tokenAddress.toLowerCase() + ":" + info.tokenId.toString(),
                                weiPrice: price,
                                expirationTime: expiresInDate.toString(),
                                options: { "seaport-v1.5": { useOffChainCancellation: true } },
                                royaltyBps: 0
                            }
                        ]
                    }).then((res: any) => {
                        addLog(`MagicEden: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`, "success");
                        toast.success(`MagicEden: ${info.tokenId.toString()} listed on ${info.address} account. Price: ${priceNum}`);
                        console.log("My me listing:", res);
                    }).catch((err: Error) => {
                        addLog(`MagicEden: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`, "error");
                        toast.error(`MagicEden: ${info.tokenId.toString()} failed to list on ${info.address} account. ${err.message}`)
                        console.error("me listing failed", err);
                    });
                }
            }
        }

        addLog("Listing process finished");
        toast.success("Tokens listed");

        await updateTokenInfos();
    }

    const totalTokenBalance = useMemo(() => {
        return tokenBalances.reduce((acc, balance) => acc + balance, 0);
    }, [tokenBalances]);

    return (
        <Box m={2}>
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <Box> Contract address: </Box>
                <Box width={400}>
                    <TextField
                        fullWidth
                        value={tokenTempAddress}
                        onChange={(e) => setTokenTempAddress(e.target.value)}
                        variant="standard"
                        placeholder="0x00000000000000000000000000000000"
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Button variant="outlined" onClick={onApplyAddress}>Check token</Button>
                    <Button onClick={updateTokenInfos} disabled={selectedAccountsSet.size === 0} variant="outlined">Update token list</Button>
                    {/* <Button variant="contained" onClick={() => {
                        updateAccountsMeta();
                    }}>Update balances</Button> */}
                </Box>
            </Box>
            <Box width={1000}>
                <AddressDataTable
                    tokenAddress={tokenAddress}
                    pubClientsMap={pubClientsMap}
                    accounts={accounts}
                    activeChainId={activeChainId}
                    activeSet={selectedAccountsSet}
                    toggleActive={toggleAccount}
                    toggleAllActive={toggleAllAccounts}
                    addLog={addLog}
                    balances={balances}
                    tokenBalances={tokenBalances}
                    approvalsOs={approvalsOs}
                    approvalsBlur={approvalsBlur}
                    updateAccountsMeta={updateAccountsMeta}
                    isLoadingBalances={isLoadingBalances}
                />
            </Box>
            <Box mt={2} width={1200}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography variant="h5" color="black">{tokenName}</Typography>
                    <Typography variant="body1" color="black">(Total nfts owned: {totalTokenBalance})</Typography>
                    <Typography variant="body1" color="black">Current marketplace: {marketplace ? marketplace : "Not selected"}</Typography>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>PNG</TableCell>
                            <TableCell>Token Id</TableCell>
                            <TableCell>Rarity</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Expires at</TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    Duration
                                    <Select size="small" onChange={(e) => selectAllDurations(e.target.value as number)}>
                                        {
                                            Durations.map((duration, durationIndex) => (
                                                <MenuItem key={duration.label + durationIndex} value={durationIndex}>{duration.label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                    <span>
                                        Price
                                    </span>
                                    <TextField
                                        type="number"
                                        value={allPricesValue}
                                        onChange={(e) => {
                                            setAllPricesValue(e.target.value);
                                            setAllTokenInputs(e.target.value);
                                        }}
                                        placeholder=""
                                        size="small"
                                        onWheel={(e) => (e.target as HTMLElement).blur()}
                                        sx={{
                                            width: "120px"
                                        }}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={selectedTokenInfosSet.size === tokensInfo.length}
                                    onChange={() => toggleAllSelectedTokenInfo()}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !isLoadingTokensMeta && tokensInfo.map((info, infoIndex) => (
                                <TableRow key={`${info.address}_${info.tokenId}`}>
                                    <TableCell>{infoIndex + 1}</TableCell>
                                    <TableCell>{formatAddress(info.address)}</TableCell>
                                    <TableCell>
                                        <img
                                            src={info.imgUrl}
                                            width="64px"
                                            height="64px"
                                            alt="token"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={
                                                marketplace === Marketplace.Opensea
                                                    ? `https://opensea.io/assets/ethereum/${tokenAddress}/${info.tokenId}`
                                                    : `https://magiceden.io/item-details/apechain/${tokenAddress}/${info.tokenId}`
                                            }
                                        >
                                            {info.tokenId}
                                        </a>
                                    </TableCell>
                                    <TableCell>{info.rarity}</TableCell>
                                    <TableCell>{info.name}</TableCell>
                                    <TableCell>{info.price}</TableCell>
                                    <TableCell>{info.expiresIn}</TableCell>
                                    <TableCell>
                                        <Select
                                            inputProps={{ 'aria-label': "controlled" }}
                                            size="small"
                                            onChange={(e) => setInfoDuration(info, e.target.value as number)}
                                            value={infoDurations[info.id] ?? 2}
                                            defaultValue={Durations.length - 1}
                                        >
                                            {
                                                Durations.map((duration, durationIndex) => (
                                                    <MenuItem key={duration.label + info.id} value={durationIndex}>{duration.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0.0000"
                                            value={tokenPriceInputs[info.id]}
                                            onWheel={(e) => (e.target as HTMLElement).blur()}
                                            onChange={(e) => setTokenPriceInput(info, e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedTokenInfosSet.has(info.id)}
                                            onChange={() => toggleSelectedTokenInfo(info)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    isLoadingTokensMeta && (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
                            <CircularProgress />
                        </Box>
                    )
                }
            </Box>
            {
                !isLoadingTokensMeta && (
                    <Box mt={2}>
                        <Button onClick={listTokens} variant="contained" disabled={!marketplace}>List</Button>
                    </Box>
                )
            }
        </Box>
    )
}
