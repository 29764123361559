import { useCallback, useState } from "react";
import { WalletClient } from "viem";
import { getHDWalletClient } from "../helpers";
import { SUPPORTED_CHAIN_IDS } from "../settings";

export type Account = {
    address: string;
    walletClients: Map<number, WalletClient>;
    isActive: boolean;
    pk: string;
}

export const useWalletList = () => {
    const [accounts, setAccounts] = useState<Account[]>([]);

    const createAccounts = useCallback((mnemonic: string, count: number, callback: Function) => {
        const accounts_: Account[] = [];

        for(let accountIndex = 0; accountIndex < count; accountIndex++) {
            const account: Account = {
                address: "",
                walletClients: new Map(),
                // TODO(Nikita): Change it to false when activation will be added
                isActive: true,
                pk: "",
            };

            for(const chainId of SUPPORTED_CHAIN_IDS) {
                const data = getHDWalletClient(mnemonic, accountIndex, chainId, chainId === 1 ? "https://mainnet.infura.io/v3/cdc5cc21b61d46ff973ca1b19ed67856" : undefined);
                const client = data[0];
                const pk = data[1];

                if(client && pk) {
                    if(account.address === "") {
                        account.address = client.account!.address;
                    }
                    if(account.pk === "") {
                        account.pk = pk;
                    }
                    account.walletClients.set(chainId, client);
                }
            }

            if(account.address !== "") {
                accounts_.push(account);
            }
        }

        setAccounts(accounts_);
        callback();
    }, []);

    return {
        accounts,
        createAccounts,
    }
};