import { useState } from "react";

export interface Log {
    type: "info" | "error" | "success";
    message: string;
    timestamp: number;
}

export const useLogs = () => {
    const [logs, setLogs] = useState<Log[]>([]);

    const addLog = (message: string, type: "info" | "error" | "success" = "info") => {
        setLogs((prevLogs) => [
            ...prevLogs,
            { type, message, timestamp: new Date().getTime() },
        ]);
    }

    return { logs, addLog };
}