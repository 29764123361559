import { useCallback } from "react";
import { usePublicClientsList } from "./usePublicClientsList"
import { listNftsByAccount, NftModel } from "../api/gen/opensea";
import axios from "axios";

export interface ExtendedNftModel extends NftModel {
    rank?: number;
    price?: number;
    expiresAt?: number;
};

export const useAddressTokens = (activeChainId: number) => {
    const clients = usePublicClientsList();

    const getTokens = useCallback(async (tokenAddress: string, address: string): Promise<ExtendedNftModel[]> => {
        try {
            const client = clients.get(activeChainId);

            if(!client) {
                console.error("No client exists for chain id:", activeChainId);
                return [];
            }

            if(activeChainId === 33139) {
                const tokensRes = await axios.get(`https://api-mainnet.magiceden.dev/v3/rtp/apechain/users/${address}/tokens/v7?normalizeRoyalties=false&sortBy=acquiredAt&sortDirection=desc&limit=20&includeTopBid=false&includeAttributes=false&includeLastSale=false&includeRawData=false&filterSpamTokens=false&useNonFlaggedFloorAsk=false`);

                const tokens: NftModel[] = tokensRes.data.tokens
                    .filter((t: any) => {
                        return t.token.contract.toLowerCase() === tokenAddress.toLowerCase()
                    })
                    .map((t: any) => {
                        const tok = t.token;
                        const own = t.ownership;

                        const result: ExtendedNftModel = {
                            collection: tok.collection.name,
                            contract: tok.contract,
                            description: tok.description,
                            identifier: tok.tokenId,
                            image_url: tok.image,
                            is_disabled: false,
                            is_nsfw: false,
                            name: tok.name,
                            token_standard: tok.kind,
                            rank: tok.rarityRank,
                            price: own.floorAsk.price?.amount.native ?? "-",
                            expiresAt: own.floorAsk.validUntil ? new Date(own.floorAsk.validUntil * 1000).getTime() : undefined,
                            updated_at: new Date().getTime().toString()
                        }

                        return result;
                    })

                return tokens;
            };

            let chainName = "ethereum" as "ethereum" | "base";

            if(activeChainId === 8453) {
                chainName = "base";
            }

            const tokensRes = await listNftsByAccount(chainName, address)

            return tokensRes.data.nfts.filter((nft) => nft.contract.toLowerCase() === tokenAddress.toLowerCase());
        } catch (err) {
            console.error("Error fetching tokens:", err);
            return [];
        }
    }, [activeChainId, clients]);

    return getTokens;
}
