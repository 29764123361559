import { useMemo } from "react"
import { Box, Button, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import type { Account } from "../hooks/useWalletList";
import { SUPPORTED_CHAIN_IDS, SUPPORTED_MARKETPLACE_CHAINS } from "../settings";
import * as chains_ from "viem/chains";
import { Log } from "../hooks/useLogs";
import { APE_CHAIN } from "../chains/custom";
import { Marketplace } from "../models/Marketplace";
import { toast } from "react-toastify";

const chains = Object.values(chains_);
// @ts-ignore
chains.push(APE_CHAIN);

interface SettingsViewProps {
    activeChainId: number;
    setActiveChainId: (chainId: number) => void;
    walletCount: number | undefined;
    setWalletCount: (count: number) => void;
    accounts: Account[],
    createAccounts: (mnemonic: string, count: number, callback: Function) => void;
    addLog: (message: string, type?: Log["type"]) => void;
    seedPhrase: string | undefined;
    setSeedPhrase: (phrase: string) => void;
    marketplace: Marketplace | undefined;
    setMarketplace: (mp: Marketplace | undefined) => void;
}

export const SettingsView = ({ accounts, activeChainId, setActiveChainId, createAccounts, walletCount, setWalletCount, addLog, seedPhrase, setSeedPhrase, marketplace, setMarketplace }: SettingsViewProps) => {

    const chainsSelectOpts = useMemo(() => {
        const result: any[] = [];

        for(const chainId of SUPPORTED_CHAIN_IDS) {
            const chain = chains.find((chain) => chain.id === chainId);

            if(!chain) {
                continue;
            }

            result.push({
                label: chain.name,
                value: chainId,
            })
        }

        return result;
    }, []);

    const onSetChainId = (event: SelectChangeEvent<number>) => {
        setActiveChainId(event.target.value as number);
        if(marketplace) {
            const supportedChainsForCurrentMp = SUPPORTED_MARKETPLACE_CHAINS[marketplace as Marketplace];
            console.log(supportedChainsForCurrentMp);
            if(!supportedChainsForCurrentMp.includes(event.target.value as number)) {
                setMarketplace(undefined);
            }
        }

        addLog(`Using different chain id ${event.target.value}`);
        toast.info(`Using different chain id ${event.target.value}`);
    };

    const onSetMarketplace = (event: SelectChangeEvent<Marketplace>) => {
        setMarketplace(event.target.value as Marketplace);
        addLog(`Using ${event.target.value} now`);
        toast.info(`${event.target.value} selected`)
    };

    const marketplaces = useMemo(() => {
        const marketplaceChains = Object.keys(SUPPORTED_MARKETPLACE_CHAINS);

        const result = [];

        for(const mpc of marketplaceChains) {
            const chains = SUPPORTED_MARKETPLACE_CHAINS[mpc as Marketplace];
            if(chains.includes(activeChainId)) {
                result.push({
                    label: mpc,
                    value: mpc,
                });
            }
        }

        return result;
    }, [activeChainId])

    return (
        <Box mt={2}>
            <Box width={200}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Box>
                        <InputLabel id="select-chain-settings-label">Select chain</InputLabel>
                        <Select
                            value={activeChainId}
                            onChange={onSetChainId}
                            labelId="select-chain-settings-label"
                            fullWidth
                        >
                            {
                                chainsSelectOpts.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                        </MenuItem>
                                ))
                            }
                        </Select>
                    </Box>
                    <Box>
                        <InputLabel id="select-mp-settings-label">Select marketplace</InputLabel>
                        <Select
                            value={marketplace}
                            onChange={onSetMarketplace}
                            labelId="select-mp-settings-label"
                            fullWidth
                        >
                            {
                                marketplaces.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} width={400}>
                <InputLabel id="select-chain-settings-label">Seed phrase</InputLabel>
                <TextField
                    fullWidth
                    value={seedPhrase}
                    onChange={(e) => setSeedPhrase(e.target.value)}
                    multiline
                    maxRows={20}
                    variant="filled"
                />
            </Box>
            <Box mt={2} width={200}>
                <InputLabel id="select-chain-settings-label">Num of addresses</InputLabel>
                <TextField
                    fullWidth
                    value={walletCount}
                    onChange={(e) => !isNaN(Number(e.target.value)) && setWalletCount(Number(e.target.value))}
                />
            </Box>
            <Box mt={2}>
                <Button
                    onClick={() => {
                        if(!seedPhrase || !walletCount || walletCount === 0) {
                            addLog("Some of values are incorrect: seed phrase, wallet count", "error");
                            return;
                        }

                        createAccounts(seedPhrase, walletCount, () => {
                            addLog("Accounts imported", "success");
                            toast.info("Accounts imported");
                        });
                    }}
                    variant="contained"
                    disabled={!seedPhrase || walletCount === 0}
                >
                    Import wallets
                </Button>
            </Box>
        </Box>
    )
}
