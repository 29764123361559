import { defineChain } from "viem";

// @ts-ignore
export const APE_CHAIN = defineChain({
    id: 33139,
    name: "Ape chain",
    nativeCurrency: {
        decimals: 18,
        name: "Ape",
        symbol: "APE",
    },
    rpcUrls: {
        default: {
            http: ["https://rpc.apechain.com"],
        }
    },
    blockExplorers: {
        default: {
            name: "ApeScan",
            key: "apescan",
            url: "https://apescan.io",
        }
    }
})